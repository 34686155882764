//parcel
//https://ja.parceljs.org/getting_started.html
///////////////
//npm run dev
///////////////
import * as THREE from 'three';
// import * as THREE from '/node_modules/three/build/three.js';
import { OrbitControls } from '/node_modules/three/examples/jsm/controls/OrbitControls.js';
import { FontLoader } from '/node_modules/three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from '/node_modules/three/examples/jsm/geometries/TextGeometry.js';
import fragment from './shaders/fragment.frag';
import vertex from './shaders/vertex.vert';
import gsap from 'gsap';
import * as dat from 'dat.gui';
import map from './img/map.png';
// import fontjson from './customStatic/helvetiker_bold.typeface.json';
//import fontjson from './font.json';
// import fontjson from './helvetiker_bold.typeface.json';

export default class Sketch {
    constructor() {


		this.time = 0;
        this.normal = new THREE.Vector3();
        this.binormal = new THREE.Vector3();
        this.texts = [];
        this.speed = 0;
        this.position = 0;
        document.addEventListener('wheel', (event)=>{
           this.speed += event.deltaY * 0.0002;
        });

        this.container = document.getElementById('container');
        this.width = this.container.offsetWidth;
        this.height = this.container.offsetHeight;
        this.renderer = new THREE.WebGLRenderer( { antialias: true } );
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        this.renderer.setSize( this.width, this.height );
        this.renderer.setClearColor(0x000000, 1);
        this.renderer.physicallyCorrectLights = true;
        // this.renderer.outputEncoding = THREE.sRGBEncoding;
        document.body.appendChild( this.renderer.domElement );

        // this.camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.01, 10 );
        // this.camera.position.z = 1;
        this.camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.001, 1000 );
        this.camera.position.set(0, 0, 10);
        this.scene = new THREE.Scene();

        //this.raycaster = new THREE.Raycaster();

        //this.controls = new OrbitControls(this.camera, this.renderer.domElement);

        this.addMesh();

        this.resize();

        console.log(THREE.REVISION);

        this.render();

        window.addEventListener('resize', this.resize.bind(this));

        this.settings();

    }

    settings() {
        let that = this;
        this.settings = {
            progress: 0,
        };
        this.gui = new dat.GUI();
        this.gui.add(this.settings, "progress", 0, 1, 0.01);
    }


    addMesh() {
        class CustomSinCurve extends THREE.Curve {

            constructor( scale = 1 ) {

                super();

                this.scale = scale;

            }

            //ここでチューブの形状を作っている
            getPoint( t, optionalTarget = new THREE.Vector3() ) {

                // const tx = t * 3 - 1.5;
                // const tx = Math.cos( 2 * Math.PI * t );
                // const ty = Math.sin( 2 * Math.PI * t );
                // const tz = 0;

                const tx = Math.cos( 2 * Math.PI * t );
                const ty = Math.sin( 2 * Math.PI * t );
                const tz = 0.1 * Math.sin( 8 * Math.PI * t );

                return new THREE.Vector3( tx, ty, tz ).multiplyScalar( this.scale );

                // t = t * 4 * Math.PI;
                // var a = this.radius / 2;
                // var x = a * (1 + Math.cos( t ));
                // var y = a * (1 + Math.sin( t ));
                // var z = 2 * a * Math.sin( t / 2 );
                // return new THREE.Vector3( x, y, z ).multiplyScalar( this.scale );

            }

        }

        const path = new CustomSinCurve( 10 );
        this.tubeGeometry = new THREE.TubeGeometry( path, 200, 1, 8, false );
        var material = new THREE.MeshBasicMaterial( {
            // color: 0x00ff00,
            side: THREE.DoubleSide,
            // wireframe: true,
            map: new THREE.TextureLoader().load(map)
            }
        );
        material.map.wrapS = THREE.RepeatWrapping;
        material.map.wrapW = THREE.RepeatWrapping;
        material.map.repeat.set(10, 1);//テクスチャを繰り返し張り付ける。繰り返さないと引き伸ばしすぎになるから
        const mesh = new THREE.Mesh( this.tubeGeometry, material );
        this.scene.add( mesh );


        const loader = new FontLoader();
        const font = loader.load(
            // resource URL
            'font.json',
             //fontjson,//distに直接配置

            // onLoad callback
             ( font ) =>{
                // do something with the font
                //console.log( font );
                // const textGeometry = new TextGeometry( 'Hello three.js!', {
                const textGeometry = new TextGeometry( 'AAAAAAAA', {
                    font: font,
                    size: 0.15,
                    height: 0.01,
                    // curveSegments: 12,
                    // bevelEnabled: true,
                    // bevelThickness: 10,
                    // bevelSize: 8,
                    // bevelOffset: 0,
                    // bevelSegments: 5
                } );
                 textGeometry.center();//センター寄せ（アンカーを中心に）
                let textmaterial = new THREE.MeshBasicMaterial({color: 0xff0000});
                let textmesh = new THREE.Mesh(textGeometry, textmaterial);
                textmesh.position.copy(
                    //テキストの座標をトンネルの座標配列のちょうど真ん中の値（Vec3）にする
                    this.tubeGeometry.parameters.path.getPointAt(0.5)
                )

                 for(var i=0; i<6; i++) {
                     let copy = textmesh.clone();
                     this.texts.push(copy);
                     this.scene.add(copy);
                     copy.position.copy(
                         //テキストの座標をトンネルの座標配列のちょうど真ん中の値（Vec3）にする
                         this.tubeGeometry.parameters.path.getPointAt(i*0.15)
                     )
                 }

                // this.scene.add(textmesh);
            },

            // onProgress callback
            function ( xhr ) {
                console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
            },

            // onError callback
            function ( err ) {
                console.log( 'An error happened' , err);
            }
        );

        // this.material = new THREE.ShaderMaterial({
        //     fragmentShader: fragment,
        //     vertexShader: vertex,
        //     uniforms: {
        //         time: {type: "f", value: 0},
        //         resolution: {value: new THREE.Vector4()}
        //     },
        //     side: THREE.DoubleSide
        //     // transparent: true,
        //     // depthTest: false,
        //     // depthWrite: false
        // });
        // // this.geometry = new THREE.PlaneBufferGeometry( 1000, 1000 , 10, 10);
        // this.geometry = new THREE.PlaneGeometry(1,1,1,1);
        // this.plane = new THREE.Mesh( this.geometry, this.material );
        // this.scene.add( this.plane );
    }

    resize() {
        this.width = this.container.offsetWidth;
        this.height = this.container.offsetHeight;
        this.renderer.setSize(this.width, this.height);
        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();

        //image cover
        this.imageAspect = 853 / 1208;
        let a1; let a2;
        if(this.height/this.width>this.imageAspect) {
            a1 = (this.width/this.height) * this.imageAspect;
            a2 = 1;
        } else {
            a1 = 1;
            a2 = (this.height/this.width) / this.imageAspect;
        }


        // this.material.uniforms.resolution.value.x = this.width;
        // this.material.uniforms.resolution.value.y = this.height;
        // this.material.uniforms.resolution.value.z = a1;
        // this.material.uniforms.resolution.value.w = a2;

        ///////////////////////////////
        //optional...cover with quad
        //////////////////////////////
        // const dist = this.camera.position.z;
        // const height = 1;
        // this.camera.fov = 2 * (180 / Math.PI) * Math.atan(height/(2*dist));
        // if(this.width/this.height > 1) {
        //     this.plane.scale.x = this.camera.aspect;
        // }else {
        //     this.plane.scale.y = 1 / this.camera.aspect;
        // }
        // this.camera.updateProjectionMatrix();
    }

    render() {
        // var time = Date.now();//自動ループ
        this.time = this.time + 2;//自動ループ
        // var time = this.time;
        var time = this.position * 400;
        var looptime = 30 * 1000;
        // var looptime = 5 * 1000;//値が小さいとスピードが早くなる
        var t = ( time % looptime ) / looptime;//0-1を繰り返す
        var pos = this.tubeGeometry.parameters.path.getPointAt( t );
        // pos.multiplyScalar( params.scale );
        // interpolation
        var segments = this.tubeGeometry.tangents.length;
        var pickt = t * segments;
        var pick = Math.floor( pickt );
        var pickNext = ( pick + 1 ) % segments;
        this.binormal.subVectors( this.tubeGeometry.binormals[ pickNext ], this.tubeGeometry.binormals[ pick ] );
        this.binormal.multiplyScalar( pickt - pick ).add( this.tubeGeometry.binormals[ pick ] );
        var dir = this.tubeGeometry.parameters.path.getTangentAt( t );
        var offset = 0;
        this.normal.copy( this.binormal ).cross( dir );
        // we move on a offset on its binormal
        pos.add( this.normal.clone().multiplyScalar( offset ) );
        this.camera.position.copy( pos );
        // using arclength for stablization in look ahead
        var lookAt = this.tubeGeometry.parameters.path.getPointAt( ( t + 1 / this.tubeGeometry.parameters.path.getLength() ) % 1 );
        // camera orientation 2 - up orientation via normal
        this.camera.matrix.lookAt( this.camera.position, lookAt, this.normal );
        this.camera.rotation.setFromRotationMatrix( this.camera.matrix, this.camera.rotation.order );

        this.texts.forEach(t=>{
           // console.log('t', t.quaternion);
            t.quaternion.copy(this.camera.quaternion);
        });

        //mouse pos
        this.position += this.speed;
        this.speed *= 0.9;
        // let po = Math.round(this.position);
        // let dif = po - this.position;
        // this.position += dif * 0.035;
        // if(Math.abs(po - this.position) < 0.001) {
        //     this.position = po;
        // }

        //this.time += 0.05;
        // this.mesh.rotation.x += 0.01;
        // this.mesh.rotation.y += 0.02;
        // console.log(this.time);
        // this.material.uniforms.time.value = this.time;
        // this.material.uniforms.transition.value = this.settings.progress;
        this.renderer.render( this.scene, this.camera );
        window.requestAnimationFrame(this.render.bind(this));
    }
}

new Sketch();